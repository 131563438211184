a{
    color: var(--parkingpass-primary);
}
.cdk-overlay-pane{
    max-width: 750px !important;
}
.btn-primary {
    color: #fff;
    background-color: var(--parkingpass-primary);
    border-color: var(--parkingpass-primary);
}

.btn-primary:hover{
    background-color: var(--parkingpass-hover-primary);
    border-color: var(--parkingpass-primary);
}

.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:not(:disabled):not(.disabled).active{
    background-color: var(--parkingpass-primary);
    border-color: var(--parkingpass-primary);
}

.btn-secondary {
    color: #fff;
    background-color: var(--parkingpass-secondary);
    border-color: var(--parkingpass-secondary);
}

.btn-secondary:hover{
    background-color: var(--parkingpass-hover-secondary);
    border-color: var(--parkingpass-secondary);
}

.btn-secondary:hover, 
.btn-secondary:not(:disabled):not(.disabled):active, 
.btn-secondary:not(:disabled):not(.disabled).active{
    background-color: var(--parkingpass-secondary);
    border-color: var(--parkingpass-secondary);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--parkingpass-hover-primary);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, 
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), 
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, 
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--parkingpass-hover-primary);
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
    opacity: 0.03 !important;
    background-color: var(--parkingpass-primary) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: var(--parkingpass-primary);
}

.mat-drawer-content {
    position: fixed !important;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding-bottom: 100px;
    overflow-y: scroll !important;
}

.ng-untouched.ng-invalid .mat-form-field-label{
    color: rgba(0, 0, 0, 54%);
}

.ng-valid .mat-form-field-label{
    color: var(--parkingpass-success-green);
}

@media (max-width: 560px) { 

    .btn-primary:hover{
        background-color: var(--parkingpass-primary);
        border-color: var(--parkingpass-primary);
    }
    
    .btn-primary:not(:disabled):not(.disabled):active, 
    .btn-primary:not(:disabled):not(.disabled).active{
        background-color: var(--parkingpass-primary);
        border-color: var(--parkingpass-primary);
    }
    
    .btn-secondary:hover{
        background-color: var(--parkingpass-secondary);
        border-color: var(--parkingpass-secondary);
    }
    
    .btn-secondary:hover, 
    .btn-secondary:not(:disabled):not(.disabled):active, 
    .btn-secondary:not(:disabled):not(.disabled).active{
        background-color: var(--parkingpass-secondary);
        border-color: var(--parkingpass-secondary);
    }  

}