html{
    position: relative;
    font-size: 19px;
    background-color: white;
    cursor: default;
    color: black;
    height: unset !important;
}
body{
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 5px;
    background-color: transparent;
    color: black;
    overflow-x : hidden;
    padding: env(safe-area-inset-top, 20px)
    env(safe-area-inset-right, 20px)
    env(safe-area-inset-left, 20px);
    height: unset !important;
    font-family: Arial, he;
}
.parkingpass-logo{
    position: relative;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 20px;
    min-width: 250px;
    max-width: 600px;
    height: 120px;
    min-height: 120px;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: center !important;
    cursor: pointer;
}
.ng2-pdf-viewer-container{
    position: relative;
    width: 100% !important;
    min-width: 100% !important;
    height: 450px !important;
    max-height: 450px !important;
    min-height: 450px !important;
    overflow-y: scroll;
}
.mat-accent .mat-slider-track-fill, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label {
    background-color: #ffffff;
}
.parkingpass-bottom-button-bar{
    position: relative;
    margin: 0 auto;
    margin-top: 40px;
}
.parkingpass-help-info{
    cursor: pointer;
    text-decoration: underline;
    background-color: transparent;
    border: 0px;
    border-color: transparent;
}
.cdk-overlay-container {
    z-index: 99999999999999;
}
.parkingpass-formFieldWrapper{
    position: relative;
    display: inline-block;
    margin: 10px;
    min-width: 47%;
    width: 47%;
    max-width: 47%;
}
div:active, div:focus{
    outline: none;
}
::-webkit-scrollbar{
    height: 10px;
    width: 10px;
    background: rgb(245, 245, 245);
}
::-webkit-scrollbar-thumb{
    background: rgb(52, 52, 52);
}
::-webkit-scrollbar-corner{
    background: rgb(52, 52, 52);
}
::-webkit-scrollbar-button{
    background-color: rgb(52, 52, 52);
}
.parkingpass-h1{
    position: relative;
    margin: 0 auto;
    text-align: center;
    color: #99cc00;
    font-size: 50px;
}
.parkingpass-h2{
    position: relative;
    margin: 0 auto;
    text-align: center;
    color: #99cc00;
}
.parkingpass-el-100{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}
.parkingpass-margin{
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}
.parkingpass-padding{
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
.parkingpass-rel-div{
    position: relative;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
}
.parkingpass-title{
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: rgb(255, 255, 255);
    color: #74bf23;
    text-align: center;
}
.parkingpass-paragraph{
    position: relative;
    width: unset;
    min-width: unset;
    padding: 20px;
    margin-bottom: 60px;
    line-height: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 2px;
    color: white;
    background-color: #e5e5e5;
    text-indent: 30px;
}
.parkingpass-no-repeat-bg-contain{
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
}
.parkingpass-img{
    position: relative;
    display: block;
    width: 450px;
    min-width: 450px;
    max-width: 450px;
    height: 350px;
    min-height: 350px;
    max-height: 350px;
    margin: 0 auto;
}
.parkingpass-el-fixed-bottom{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 20px;
    margin: 0 auto;
    width: 550px;
    min-width: 550px;
    max-width: 550px;
    text-align: center;
    color: #fff;
    z-index: 99;
    background-color: #7bb126;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 28px;
    border-radius: 20px;
    cursor: pointer;
}
.parkingpass-info-text{
    position: relative;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    text-align: center;
    background-color: rgba(0,0,0,.23);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
}
.parkingpass-link{
    color: #7bb126;
    cursor: pointer;
    letter-spacing: 4px;
}
.parkingpass-color-label{
    display: none;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #FFF;
    font-size: 16px;
}
.parkingpass-color-picker{
    position: relative;
    margin: 0 auto;
    width:  50px;
    min-width: 50px;
    max-width: 50px;
    height:  50px;
    min-height: 50px;
    max-height: 50px;
    background-color: white;
    border-radius: 99%;
    box-shadow: inset 2px 2px 5px #9e9e9e;
}
.parkingpass-close-button{
    position: fixed;
    margin: 0 auto;
    bottom: 1%;
    left: 0;
    right: 0;
    max-width: 135px;
    z-index: 1000;
    text-align: center;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    color: #FFF;
    font-size: 18px;
    border-radius: 17px;
    background-color: rgb(20, 20, 20);
}
.parkingpass-overlay-window{
    position: fixed;
    left: 0;
    text-align: center;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    z-index: 99999999;
    overflow-y: scroll;
    background-color: rgba(0,0,0,.93);
    padding-top: env(safe-area-inset-top, 20px);
}
.parkingpass-input{
    position: relative;
    display: block;
    margin: 0 auto;
    font-size: 25px;
    color: black;
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    letter-spacing: 1px;
}
.parkingpass-input-info{
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    text-align: center;
    letter-spacing: 3px;
}
.parkingpass-invalid-feedback{
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    text-align: center;
    letter-spacing: 3px;
    line-height: 40px;
}
.parkingpass-toggle-info-text{
    text-align: center;
}
.parkingpass-toggle-light{
    border-radius: 14px;
    max-width: 15%;
    width: 15%;
    min-width: 15%;
}
.parkingpass-toggle-help-switch{
    min-width: 10%;
    max-width: 10%;
    width: 10%;
}
.parkingpass-play-button{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.66);
    margin: 0 auto;
    left: 0px;
    right: 0px;
    max-width: 50px;
    width: 50px;
    min-width: 50px;
    border-radius: 99%;
    padding-top: 13px;
    padding-bottom: 13px;
    text-align: center;
}
.parkingpass-textarea{
    position: relative;
    display: block;
    margin: 0 auto;
    width: 90%;
    min-width: 90%;
    max-width: 90%;
    height: 240px;
    min-height: 240px;
    resize: vertical;
    max-height: 650px;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    border: none;
    background-color: rgba(0,0,0,.23);
    font-size: 25px;
    border-radius: 15px;
}
.parkingpass-textarea:active{
    outline : none;
}
.parkingpass-button{
    display: block;
    margin: 0 auto;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    background-color: #7bb126;
    border: none;
    border-radius: 20px;
    color: inherit;
    font-size: 19px;
    cursor: pointer;
    letter-spacing: 2px;
    color: white;
    padding: 0px;
    margin-top: 25px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    text-align: center;
}
.parkingpass-td-button{
    display: table-cell;
}
.parkingpass-help-text{
    position: relative;
    margin: 0 auto;
    width: 500px;
    min-width: 500px;
    max-width: 500px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: rgba(0,0,0,.2);
    letter-spacing: 3px;
    line-height: 32px;
}
.parkingpass-menu-selected{
    background-color: rgba(0,0,0,.5);
}
.parkingpass-load-more-button{
    position: relative;
    background-color: rgba(0,0,0,.23);
    text-align: center;
    margin-top: 40px;
}
.parkingpass-loading-screen{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 99999999998;
    text-align: center;
}
.parkingpass-loading-gif{
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 150px;
    min-height: 150px;
    max-height: 150px;
}
.parkingpass-main-holder{
    position: relative;
}
.parkingpass-select-menu{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: inherit !important;
    z-index: 99999999999;
    text-align: center;
    overflow-y: scroll;
}
.parkingpass-select-menu-option{
    position: relative;
    margin: 0 auto;
    width: 450px;
    min-width: 450px;
    max-width: 450px;
}
.parkingpass-select-table{
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    cursor: pointer;
}
.parkingpass-select-table-text{
    background-color: rgba(0,0,0,.23);
    text-align: left;
    padding-left: 18px;
}
.parkingpass-select-table-img{
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-color: white !important;
}
.parkingpass-toast-input-overlay{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    background-color: rgba(0,0,0,.82);
    z-index: 99999999999;
    overflow-y: scroll;
}
.parkingpass-toast-input-box{
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 20px;
    margin: 0 auto;
    max-width: 750px;
    border-radius: 20px;
    z-index: 9999999999;
    text-align: center;
}
.parkingpass-button-mini{
    margin-top: 20px;
    font-size: 18px;
    background-color: rgba(0,0,0,.23);
}
.parkingpass-error-display{
    display: none;
    position: fixed;
    overflow-y: scroll;
    width: 1200px;
    height: 100%;
    top: 0;
    bottom: 0;
    z-index: 999999999999;
    background-color: rgba(0,0,0,.93);
}
.parkingpass-scroll-top{
    position: fixed;
    left: 15px;
    bottom: 15px;
    transition: 0.5s;
    background-color: #609329;
    padding: 5px;
    padding-left: 13px;
    padding-right: 13px;
    border-radius: 99%;
    transition: 0.5s;
    z-index: 999999;
}
.parkingpass-scroll-top:hover{
    cursor: pointer;
    background-color: #609329;
}
.parkingpass-scroll-top i{
    font-size: 38px;
    opacity: 0.7;
}
.parkingpass-scroll-top:hover i{
    opacity: 1;
}
.parkingpass-arrow-transparent{
    display: none;
}
.parkingpass-toast-message{
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.82);
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 22px;
    z-index: 2;
    border-radius: 20px;
    padding-left: 2%;
    padding-right: 2%;
    max-width: 350px;
    text-align: center;
    letter-spacing: 4px;
}
.parkingpass-options-bubble-wrap{
    position: relative;
    max-width: 300px;
    min-width: 300px;
    width: 300px;
    background-color: rgba(0,0,0,.23);
    border-radius: 10px;
}
.parkingpass-x-close{
    position: absolute;
    background-color: rgba(0,0,0,.23);
    cursor : pointer;
    border-radius: 999px;
    padding-left: 8px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
}
.parkingpass-x-close:hover{
    background-color: red;
    color: white;
}
.parkingpass-x-close i{
    font-size: 24px;
}
.parkingpass-error-red{
    color: red;
}
.parkingpass-see-more{
    position: relative;
    width: 150px;
    max-width: 150px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    cursor: pointer;
}
.parkingpass-coming-soon{
    position: relative;
    width: 98%;
    min-width: 98%;
    margin-top: 20%;
    font-size: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 1%;
    padding-right: 1%;
    text-align: center;
    background-color: rgba(0,0,0,.23);
}
.parkingpass-coming-soon-desc{
    position: relative;
    margin: 0 auto;
    width: 98%;
    min-width: 98%;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    background-color: rgba(0,0,0,.23);
}
.backgroundImgOverlay{
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    background-color: rgba(0, 0, 0, .32);
}
.parkingpass-confirm-box{
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    background-color: rgba(0,0,0,.85);
    text-align: center;
}
.parkingpass-confirm-box-inner{
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 20px;
    padding: 20px;
}
.parkingpass-confirm-box-table{
    position: relative;
    margin: 0 auto;
    margin-top: 20px;
    table-layout: fixed;
    max-width: 450px;
    width: 450px;
}
.parkingpass-confirm-box-table td{
    width: 50%;
    min-width: 50%;
    max-width: 50%;
}
.parkingpass-confirm-box-table td .parkingpass-button{
    font-size: 16px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}
.color-picker {
    display: block;
    visibility: visible;
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    position: fixed;
    margin: 0 auto !important;
    background-color: #1b1b1b !important;
    border: 20px solid #212121 !important;
}
.parkingpass-color-picker-ok{
    position: relative;
    display: block;
    margin: 0 auto !important;
    max-width: 80%;
    width: 80%;
    min-width: 80%;
    font-size: 14px !important;
    letter-spacing: 2px !important;
    background-color: rgba(0,0,0,.4);
}
.color-picker .button-area {
    margin-top: 20px;
    margin-bottom: 20px;
}
.gm-style .gm-style-iw-c{
    overflow: hidden !important;
    padding: 12px !important;
}
.gm-style-iw-d{
    overflow: hidden !important;
}
.vinPlateInfoPanel{
    min-width: 900px;
    width: 900px;
    max-width: 900px !important;
    height: 834px;
    min-height: 834px;
    max-height: 834px;
}

.errorHandlerInfoPanel{
    padding: 40px;
}

/**WIDTH MEDIA QUERIES */
@media (max-width: 1300px) {

}
@media (max-width: 1200px) {

}
@media (max-width: 1024px) {
    .parkingpass-paragraph{
        color: #ffffff;
        background-color: #696969;
        box-shadow: 0 0 4px #000;
    }
    .parkingpass-overlay-window{
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 900px) {
    .parkingpass-h1{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        font-size: 35px;
        letter-spacing: 2px;
    }
    .parkingpass-h2{
        display: block;
        width: 95% !important;
        min-width: 95% !important;
        max-width: 95% !important;
        font-size: 27px;
        letter-spacing: 2px;
        line-height: 44px;
    }
    .parkingpass-img{
        height: unset;
        min-height: unset;
        max-height: unset;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
    .vinPlateInfoPanel{
        min-width: 100%;
        width: 100%;
        max-width: 100% !important;
        height: 100vh;
        min-height: 100vh;
        max-height: 100vh;
    }
}
@media (max-width: 768px) {
    .parkingpass-logo{
        margin:0 auto;
        margin-top: 30px;
        margin-bottom: 20px;
        background-position: center !important;
        width: 50%;
        min-width: 50%;
        max-width: 50%;
    }
    .card-body{
        max-width: 90%;
        width: 90%;
        min-width: 90%;
    }
    .parkingpass-formFieldWrapper {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
    }
    mat-form-field{
        max-width: 97%;
        min-width: 97%;
        width: 97%;
    }
}
@media (max-width: 700px) {

}
@media (max-width: 600px) {
    .parkingpass-el-fixed-bottom{
        width: 90%;
        min-width: 90%;
        max-width: 90%;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 26px;
    }
}
@media (max-width: 560px) {
    .parkingpass-formFieldWrapper{
        position: relative;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 10px;
        min-width: 96%;
        width: 96%;
    }
    .card-body {
        max-width: 96%;
        min-width: 96%;
        width: 96%;
        padding-left: 7px;
        padding-right: 7px;
    }
}
@media (max-width: 460px) {
    html{
        font-size: 17px;
    }
    .parkingpass-toast-input-box{
        border-radius: 0px;
    }
    .parkingpass-input {
        font-size: 20px;
        border-radius: 12px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 7px;
        padding-bottom: 7px;
    }
    .parkingpass-select-menu-option{
        position: relative;
        margin: 0 auto;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
    .parkingpass-scroll-top i {
        font-size: 30px;
    }
}
@media (max-width: 420px) {

}
@media (max-width: 360px) {
    .parkingpass-select-table-img{
        width: 15%;
        min-width: 15%;
        max-width: 15%;
        height: 60px;
        min-height: 60px;
        max-height: 60px;
    }
}

@media (max-width: 320px) {

}
@media (max-width: 220px) {

}
@media (max-width: 120px) {

}

html, body { height: 100%; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
